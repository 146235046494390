<template>
  <v-scale-screen width="1920" height="1080" :fullScreen="true">
    <div id="electricity">
      <div class="consumption">
        <div class="subtitle">
          <img src="../../assets/images/electricity/title.png" alt=""/>
          <p>用电情况</p>
        </div>
<!--        <ul class="consumption-chart-icon">-->
<!--          <li class="power-top">尖</li>-->
<!--          <li class="power-peak">峰</li>-->
<!--          <li class="power-flat">平</li>-->
<!--          <li class="power-valley">谷</li>-->
<!--        </ul>-->
        <el-date-picker v-model="periodMonth" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="expireTimeOPtion" :clearable="false"
                        popper-class="echarts-picker" @change="monthChange">
        </el-date-picker>
        <div id="consumptionChart"></div>
      </div>
      <header class="header">
        <div class="flex-horizontal-between">
          <ul class="nav">
            <li class="home-page" @click="jumpHome">
              <img src="../../assets/images/electricity/home-page.png" alt=""/>
              <span>首页</span>
            </li>
            <li class="date">
              <img src="../../assets/images/electricity/date.png" alt=""/>
              <span>{{ date }} {{ week }} {{ time }} </span>
            </li>
          </ul>
          <div class="title">
            <img src="../../assets/images/electricity/logo.png" alt=""/>
            <span>智慧能源聚合管理平台 驾驶舱-荷</span>
          </div>
          <ul class="operate">
            <li class="address">
              <img src="../../assets/images/cockpit/address.png" alt=""/>
              <span>地区：北京 天气：{{ tq.tqxx }} 风向：{{ tq.fxms }} 空气质量：{{
                  tq.kqzl
                }}</span>
            </li>
            <li class="user">
              <img src="../../assets/images/cockpit/avatar.png" alt=""/>
              <span>{{ userInfo.userName }}</span>
            </li>
            <div class="line"></div>
            <li class="exit" @click="loginOut">
              <img src="../../assets/images/cockpit/exit.png" alt=""/>
              <span>退出</span>
            </li>
          </ul>
        </div>
      </header>
      <div class="main">
        <div class="content-left">
          <div class="subtitle">
            <img src="../../assets/images/electricity/title.png" alt=""/>
            <p>
              主要负荷设备统计
            </p>
          </div>
          <ul class="now-data">
            <div class="flex-horizontal-between">
              <li class="station">
                <img src="../../assets/images/electricity/station.png" alt=""/>
                <div>
                  <span>{{ Number(realData['2']?.totalEnergy / 1000).toFixed(2) }}kW</span>
                  <p>办公</p>
                </div>
              </li>
              <li class="transformer">
                <img src="../../assets/images/electricity/station2.png" alt=""/>
                <div>
                  <span style="color: #52a2ff">{{
                      Number(realData['3']?.totalEnergy / 1000).toFixed(2)
                    }}kW</span>
                  <p>生活</p>
                </div>
              </li>
            </div>
            <div class="flex-horizontal-between">
              <li class="station">
                <img src="../../assets/images/electricity/station3.png" alt=""/>
                <div>
                  <span>{{ Number(realData['1']?.totalEnergy / 1000).toFixed(2) }}kW</span>
                  <p>生产</p>
                </div>
              </li>
              <li class="transformer">
                <img src="../../assets/images/electricity/station4.png" alt=""/>
                <div>
                  <span style="color: #52a2ff">{{
                      Number(realData['99']?.totalEnergy / 1000).toFixed(2)
                    }}kW</span>
                  <p>其他</p>
                </div>
              </li>
            </div>
          </ul>
          <div class="fault">
            <div class="subtitle">
              <img src="../../assets/images/electricity/title.png" alt=""/>
              <p>上月负荷用能费用占比</p>
            </div>
            <div id="failureChart"></div>
            <ul class="failure-legend">
              <li>
                <div class="failure-square" style="background: #2ee1b2"></div>
                <p style="margin-right: 14px">充电桩</p>
                <p style="margin-right: 10px">{{ byTypeObj['1']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['1']?.cost }}元</p>
              </li>
              <li>
                <div class="failure-square" style="background: #18bfe9"></div>
                <p style="margin-right: 14px">空调</p>
                <p style="margin-right: 10px">{{ byTypeObj['2']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['2']?.cost }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #3775ee"></div>
                <p style="margin-right: 14px">空压机</p>
                <p style="margin-right: 10px">{{ byTypeObj['3']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['3']?.cost }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #0e49bb"></div>
                <p style="margin-right: 28px">电动机</p>
                <p style="margin-right: 10px">{{ byTypeObj['4']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['4']?.cost }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #f0c025"></div>
                <p style="margin-right: 28px">照明</p>
                <p style="margin-right: 10px">{{ byTypeObj['5']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['5']?.cost }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #ec4c3b"></div>
                <p style="margin-right: 28px">电梯</p>
                <p style="margin-right: 10px">{{ byTypeObj['6']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['6']?.cost }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #e552ff"></div>
                <p style="margin-right: 28px">展厅设备</p>
                <p style="margin-right: 10px">{{ byTypeObj['7']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['7']?.cost }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #be0000"></div>
                <p style="margin-right: 28px">其他</p>
                <p style="margin-right: 10px">{{ byTypeObj['99']?.costPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['99']?.cost }}元</p>
              </li>
            </ul>
          </div>
          <div class="trend" style="position: relative;">
            <div class="subtitle">
              <img src="../../assets/images/electricity/title.png" alt=""/>
              <p>上月负荷用能占比</p>
            </div>
            <div id="trendChart"></div>
            <ul class="failure-legend">
              <li>
                <div class="failure-square" style="background: #2ee1b2"></div>
                <p style="margin-right: 14px">充电桩</p>
                <p style="margin-right: 10px">{{ byTypeObj['1']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['1']?.energy }}元</p>
              </li>
              <li>
                <div class="failure-square" style="background: #18bfe9"></div>
                <p style="margin-right: 14px">空调</p>
                <p style="margin-right: 10px">{{ byTypeObj['2']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['2']?.energy }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #3775ee"></div>
                <p style="margin-right: 14px">空压机</p>
                <p style="margin-right: 10px">{{ byTypeObj['3']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['3']?.energy }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #0e49bb"></div>
                <p style="margin-right: 28px">电动机</p>
                <p style="margin-right: 10px">{{ byTypeObj['4']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['4']?.energy }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #f0c025"></div>
                <p style="margin-right: 28px">照明</p>
                <p style="margin-right: 10px">{{ byTypeObj['5']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['5']?.energy }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #ec4c3b"></div>
                <p style="margin-right: 28px">电梯</p>
                <p style="margin-right: 10px">{{ byTypeObj['6']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['6']?.energy }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #e552ff"></div>
                <p style="margin-right: 28px">展厅设备</p>
                <p style="margin-right: 10px">{{ byTypeObj['7']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['7']?.energy }}元</p>
              </li>
              <li class="">
                <div class="failure-square" style="background: #be0000"></div>
                <p style="margin-right: 28px">其他</p>
                <p style="margin-right: 10px">{{ byTypeObj['99']?.energyPercent + '%' }}</p>
                <!--                <p>{{ powerCut.onInPlan }}</p>-->
                <p>{{ byTypeObj['99']?.energy }}元</p>
              </li>
            </ul>
          </div>
          <div class="demand">
            <div class="subtitle">
              <img src="../../assets/images/electricity/title.png" alt=""/>
              <p>健康指数</p>
            </div>
            <div id="demandChart"></div>
            <ul class="demand-legend">
              <li class="qxs">
                <p style="margin-right: 44px">缺馅数</p>
                <p style="">{{ "11" }}</p>
              </li>
              <li class="xqs">
                <p style="margin-right: 44px">消缺数</p>
                <p>{{ "25" }}</p>
              </li>
              <div class="demand-bottom">
                <div class="device-state">
                  <p class="title-state">良好</p>
                  <p>设备健康状态</p>
                </div>
                <div class="device-rate">
                  <p class="title-rate">86.61%</p>
                  <p>设备健康率</p>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="middle">
          <!-- 搜索场站图表 -->
          <div class="bus-stop" @click="openDialog">场站</div>
          <!-- 场站总用电量GWh -->
          <div class="total-electricity">
            <div class="text">
              <img src="../../assets/images/electricity/total-electricity.png" alt=""/>
              <span>总用电量GWh</span>
            </div>
            <ul class="total">
              <li v-for="(item, index) in totalPower" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="map">
            <div id="mapChart"></div>
            <div v-if="stationDialog" class="station-info">
              <!-- <div class="arrow"></div> -->
              <div class="pop-ups">
                <div class="dialog-title">
                  <img src="../../assets/images/cockpit/mark.png" alt=""/>
                  <p>{{ stationInfo.stationName }}</p>
                </div>
                <ul class="dialog-content">
<!--                  <li class="year-electricity">-->
<!--                    <div class="year-GWh">-->
<!--                      <span>{{ stationInfo.yearPower || 0 }}</span>-->
<!--                      <p>年总用电量 (GWh)</p>-->
<!--                    </div>-->
<!--                  </li>-->
                  <li class="dialog-form">
                    <div>客户名称:</div>
                    <p>&nbsp{{ stationInfo.companyInfoName }}</p>
                  </li>
                  <li class="dialog-form">
                    <div>场站形式:</div>
                    <p v-if="stationInfo.type == '1'">&nbsp{{ '配电室' }}</p>
                    <p v-if="stationInfo.type == '2'">&nbsp{{ '箱变' }}</p>
                    <p v-if="stationInfo.type == '3'">&nbsp{{ '柱变' }}</p>
                    <p v-if="stationInfo.type == '4'">&nbsp{{ '强电间' }}</p>
                    <p v-if="stationInfo.type == '5'">&nbsp{{ '35kv/10kv开关站' }}</p>
                    <p v-if="stationInfo.type == '6'">&nbsp{{ '220kv/110kv变电站' }}</p>
                    <p v-if="stationInfo.type == '7'">&nbsp{{ '10kv分界室' }}</p>
                    <p v-if="stationInfo.type == '8'">&nbsp{{ 'xxkv升压站' }}</p>
                  </li>
                  <li class="dialog-form">
                    <div>设备数量:</div>
                    <p>&nbsp{{ stationInfo.loadEquipmentAmount }}</p>
                  </li>
                  <li class="dialog-form">
                    <div>场站地址:</div>
                    <el-tooltip class="item" effect="dark" :content="stationInfo.address" placement="top">
                      <p>&nbsp{{ stationInfo.address.length > 8 ? stationInfo.address.substring(0,8) + '...' : stationInfo.address.substring(0,8) }}</p>
                    </el-tooltip>
                  </li>
<!--                  <li class="dialog-form">-->
<!--                    <div>昨日用电量</div>-->
<!--                    <p>{{ stationInfo.yesterdayElectricity || 0 }}kWh</p>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <button :class="isStringInArray('lookItemElectricityHome') ? '' : 'btnShowAuthority'-->
<!--                      " @click="goDetails()">-->
<!--                      查看详细 >>-->
<!--                    </button>-->
<!--                  </li>-->
                </ul>
                <div class="dialog-button dialog-content">
                  <button :class="isStringInArray('lookItemElectricityHome') ? '' : 'btnShowAuthority'
                      " @click="goDetails()">
                    查看详细 >>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ul class="weather">
            <li v-if="tq.tqxx == '大风'">
              <img src="../../assets/images/cockpit/wind.png" alt=""/>
            </li>
            <li v-if="tq.tqxx == '暴雨'">
              <img src="../../assets/images/cockpit/rain.png" alt=""/>
            </li>
          </ul>
        </div>
        <div class="content-right">
          <div class="failure">
            <div class="subtitle">
              <img src="../../assets/images/electricity/title.png" alt=""/>
              <p>上月能耗占比</p>
            </div>
            <div style="display: block; width: 100%; padding: 0 20px; margin-top: 40px;">
              <div class="flex-horizontal-between">
                <div style="width: 50%;">
                  <div class="ac-con">
                    <p style="padding-top: 8px;">交流能耗</p>
                    <p class="num-ac">{{ Number(byPowerTypeAndTypeObj.total?.energy / 1000).toFixed(2) + ' kWh' }} </p>
                  </div>
                  <div style="margin-top: 20px;">
                    <div style="display: flex;justify-content: flex-start;">
                      <div style="width: 44%;">
                        <div id="lastMonthRate"></div>
                        <div style="text-align: center; font-size: 14px; color: #c5d8ff; margin-left: -16px;">交流</div>
                      </div>
                      <div style="width: 46%;">
                        <div>
                          <ul class="lastMonthRate-legend">
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['1']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #2ee1b2;"></span>
                                交流充电桩
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['2']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #18bfe9;"></span>
                                交流空调
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['3']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #3775ee;"></span>
                                交流空压机
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['4']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #0e49bb;"></span>
                                交流电动机
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['5']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #f0c025;"></span>
                                交流照明
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['6']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #ec4c3b;"></span>
                                交流电梯
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['7']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #e552ff;"></span>
                                交流展厅设备
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj['99']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #be0000;"></span>
                                交流其他
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 50%;">
                  <div class="dc-con">
                    <p style="padding-top: 8px;">直流能耗</p>
                    <p class="num-dc">{{ Number(byPowerTypeAndTypeObj1.total?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                  </div>
                  <div style="margin-top: 20px;">
                    <div style="display: flex;justify-content: flex-start;">
                      <div style="width: 44%;">
                        <div id="lastMonthRate2"></div>
                        <div style="text-align: center; font-size: 14px; color: #c5d8ff; margin-left: -16px;">直流</div>
                      </div>
                      <div style="width: 46%;">
                        <div>
                          <ul class="lastMonthRate-legend">
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['1']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #2ee1b2;"></span>
                                直流充电桩
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['2']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #18bfe9;"></span>
                                直流空调
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['3']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #3775ee;"></span>
                                直流空压机
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['4']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #0e49bb;"></span>
                                直流电动机
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['5']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #f0c025;"></span>
                                直流照明
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['6']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #ec4c3b;"></span>
                                直流电梯
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['7']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #e552ff;"></span>
                                直流展厅设备
                              </p>
                            </li>
                            <li>
                              <p style="padding-top: 4px;">
                                {{ Number(byPowerTypeAndTypeObj1['99']?.energy / 1000).toFixed(2) + ' kWh' }}</p>
                              <p>
                                <span class="failure-square" style="background: #be0000;"></span>
                                直流其他
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="accident" style="margin-top: 24px;">
            <div class="flex-horizontal-between">
              <li>
                <img src="../../assets/images/electricity/special-new.png" alt=""/>
                <div>
                  <span style="color: #c92749">{{
                      lastYear || 0
                    }}</span>
                  <p>{{ lastYearNum }}产值单耗(kWh/万元)</p>
                </div>
              </li>
              <li>
                <img src="../../assets/images/electricity/major-new.png" alt=""/>
                <div>
                  <span style="color: #ff6a24">{{
                      nowYear || 0
                    }}</span>
                  <p>{{ nowYearNum }}产值单耗(kWh/万元)</p>
                </div>
              </li>
            </div>
            <div class="flex-horizontal-between">
              <li>
                <img src="../../assets/images/electricity/serious-new.png" alt=""/>
                <div>
                  <span style="color: #f6cd29">{{
                      energySavingRate || 0
                    }}</span>
                  <p>实际节能率</p>
                </div>
              </li>
              <li>
                <img src="../../assets/images/electricity/ordinary-new.png" alt=""/>
                <div>
                  <span style="color: #51a1ff">{{
                      targetAchievementRate || 0
                    }}</span>
                  <p>目标达成率</p>
                </div>
              </li>
            </div>
          </ul>
          <div class="load">
            <div class="subtitle">
              <img src="../../assets/images/electricity/title.png" alt=""/>
              <p>产值单耗趋势</p>
            </div>
            <div id="loadChart"></div>
          </div>
        </div>
      </div>
      <div v-show="screenDialog" class="dialog">
        <div class="close">
          <img src="../../assets/images/dialog/close.png" alt="" @click="screenDialog = false"/>
        </div>
        <div class="screen-title">请选择要查询的场站</div>
        <div class="screen-subtitle" style="margin-top: 17px">
          <img src="../../assets/images/dialog/branch.png" alt=""/>
          <span>客户</span>
        </div>
        <div style="height: 150px">
          <ul class="screen-content">
            <li v-for="(item, index) in branchData" :style="{ color: item.id === branchId ? '#ffc74a' : '' }"
                :key="index" @click="branchClick(item)">
              {{ item.companyName }}
            </li>
          </ul>
        </div>

        <div class="screen-subtitle">
          <img src="../../assets/images/dialog/property.png" alt=""/>
          <span>运维单位</span>
        </div>
        <div style="height: 150px">
          <ul class="screen-content">
            <li class="property" v-for="(item, index) in propertyData" :key="index" @click="propertyClick(item)"
                :style="{ color: item.id === propertyId ? '#ffc74a' : '' }">
              {{ item.companyName }}
            </li>
          </ul>
        </div>

        <div class="station-title">
          <div class="screen-subtitle">
            <img src="../../assets/images/dialog/station.png" alt=""/>
            <span>场站</span>
          </div>
          <div class="search">
            <el-input placeholder="请根据场站名称进行搜索" v-model="stationName"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="getQueryStation">搜索</el-button>
          </div>
        </div>
        <div style="height: 150px">
          <ul class="screen-content">
            <li class="all-station" v-for="(item, index) in stationData" :key="index" @click="stationClick(item)">
              {{ item.stationName }}
            </li>
          </ul>
        </div>

        <!-- <div class="dialog-footer">
          <button>取消</button>
          <button>确定</button>
        </div> -->
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import * as echarts from "echarts";
import jingjinji from "../../assets/json/jingjinji.json";
import {monitoringLeftApi} from "@/api/monitoringLeft.js";
import {loginApi} from "@/api/login.js";
import {cockpitApi} from "@/api/cockpit.js";
import {cockpitSecondApi} from "@/api/cockpitSecond";
import moment from "moment";
import "moment/locale/zh-cn"; // 引入中文语言包
moment.locale("zh-cn"); // 设置语言为中文
export default {
  components: {},
  data() {
    return {
      authorityListBtn: [],
      screenDialog: false,
      stationId: "",
      branchId: "",
      propertyId: "",
      stationName: "",
      branchData: [],
      propertyData: [],
      stationData: [],
      userInfo: {},
      date: "",
      time: "",
      week: "",
      year: "",
      timer: null,
      stationDialog: false,
      stationInfo: {},
      coordinate: [],
      mapChart: null,
      nowYear: '',
      nowYearNum: '',
      lastYear: '',
      lastYearNum: '',
      energySavingRate: '',
      targetAchievementRate: '',
      realData: {}, //实时数据
      byTypeObj: {},
      byPowerTypeAndTypeObj: {},
      byPowerTypeAndTypeObj1: {},
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;  //如果没有后面的-8.64e6就是不可以选择今天的
        }
      },
      averageAlarm: {
        alarmCount: "",
      }, //告警情况统计
      seriousAlarm: {
        alarmCount: "",
      }, //告警情况统计
      majorAlarm: {
        alarmCount: "",
      }, //告警情况统计
      specialAlarm: {
        alarmCount: "",
      }, //告警情况统计
      powerCut: {},
      powerCut1: {},
      totalPower: "",
      rushRepair: {},
      keepRepair: {},
      periodMonth: "",
      tq: {},
      day: "",
    };
  },
  created() {
    // console.log(moment().clone().add(1,'months').format('yyyy-MM-DD'),'123')
    this.day = moment().format("DD")
    this.periodMonth = moment(moment().add(-1, 'days').startOf('day').valueOf()).format('yyyy-MM-DD')
    // this.periodMonth = moment().clone().add(1,'months').format('yyyy-MM-DD')
    // this.periodMonth = moment().format("yyyy-MM-DD")
    // const date = new Date(); // 获取当前时间
    // let year = date.getFullYear(); // 获取当前年份
    // let month = date.getMonth() + 1; // 获取当前月份
    // this.day = date.getDate();
    // if (month < 10) {
    //   this.periodMonth = `${year}-0${month}`;
    // } else {
    //   this.periodMonth = `${year}-${month}`;
    // }

  },
  mounted() {
    // 获取登录用户信息
    this.userInfo = JSON.parse(sessionStorage.getItem("user_info"));
    this.authorityListBtn = sessionStorage.getItem("roleInfo");
    // 获取当前时间
    this.time = moment().format("LTS");
    this.week = moment().format("dddd");
    this.date = moment().format("LL");
    this.year = moment().format("yyyy");
    this.currentTime();
    this.init();
    this.setWeather();
  },
  methods: {
    //天气接口
    setWeather() {
      // 创建一个 Date 对象
      var now = new Date();
      // 获取当前年份
      var currentYear = now.getFullYear();
      // 获取当前月份（注意，月份是从0开始计数的，所以需要加1）
      var currentMonth = now.getMonth() + 1;
      // 获取当前日期
      var currentDate = now.getDate();
      //获取当前小时
      var currentHour = now.getHours();
      if (currentMonth < 10) {
        currentMonth = '0' + currentMonth
      }
      if (currentDate < 10) {
        currentDate = '0' + currentDate
      }
      let param = {
        qybms: "110000",
        fPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ":00",
        tPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ":00",
      };
      monitoringLeftApi.getWeatherHourData(param).then((res) => {
        if (res.code == 200) {
          this.tq = res.data[0].sdtqInfos[0];
          // console.log("111111",this.tq);
        }
      });
    },
    //查看详细按钮权限
    isStringInArray(str) {
      // let menuList =
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list;
      } else {
        return false;
      }
    },
    currentTime() {
      this.timer = setInterval(() => {
        this.time = moment().format("LTS");
      }, 500);
    },
    init() {
      this.getQueryStation();
      // this.queryRealData();
      this.queryAlarmStatistic();
      // this.queryWeekElectricity();
      this.queryPowerQuality();
      // this.queryPeriodTime();
      this.queryLoadStatistic();
      this.queryTaskStatistic();
      // this.queryPowerCut();
      // this.queryPowerCut1();
      this.queryDevice();


      this.getByUsageType()
      this.getByType()
      this.getByPowerTypeAndType()
      this.getByYears()
      this.getUnitConsumption()
      this.getStationsAll()
      this.getHourly()
    },
    openDialog() {
      this.stationDialog = false;
      this.screenDialog = !this.screenDialog;
    },
    //分中心点击
    branchClick(item) {
      if (this.branchId == item.id) {
        this.branchId = null;
      } else {
        this.branchId = item.id;
      }
      this.getQueryStation();
    },
    //产权单位点击
    propertyClick(item) {
      if (this.propertyId == item.id) {
        this.propertyId = null;
      } else {
        this.propertyId = item.id;
      }
      this.getQueryStation();
    },
    //场站选择
    stationClick(item) {
      this.stationId = item.id;
      this.coordinate = [item.longitude, item.latitude];
      this.screenDialog = false;
      this.drawMap("search");
    },
    //场站选择
    monthChange() {
      this.getHourly()
    },
    //首页跳转
    jumpHome() {
      this.$router.push("/");
    },
    //退出
    loginOut() {
      // loginApi.exit().then((res) => {
      //   if (res.code === 200) {
      //     this.$message({
      //       message: res.message,
      //       type: "success",
      //       duration: 3000,
      //       customClass: "messageText",
      //     });
      //     sessionStorage.clear();
      //     this.$router.push("/login");
      //   }
      // });
      // window.location.href =
      //   "http://10.56.3.11:11001/cas/logout?service=http://10.170.8.27:8080/casLoginCallbac";
      sessionStorage.clear();
      localStorage.clear();
    },
    //查看详细
    goDetails() {
      sessionStorage.setItem("stationNumber", this.stationInfo.stationNumber);
      sessionStorage.setItem("stationName", this.stationInfo.stationName);
      sessionStorage.setItem("stationId", this.stationId);
      this.$router.push({
        path: "/lotusDetail",
      });
    },
    // 告警情况统计
    queryAlarmStatistic() {
      cockpitApi.queryAlarmStatistic().then((res) => {
        if (res.code === 200) {
          if (res.data.length !== 0) {
            res.data.forEach((element) => {
              if (element.level_name === "严重告警") {
                this.seriousAlarm = element; //严重
              }
              if (element.level_name === "一般告警") {
                this.averageAlarm = element; //严重
              }
              if (element.level_name === "重大告警") {
                this.majorAlarm = element; //严重
              }
              if (element.level_name === "特大告警") {
                this.specialAlarm = element; //严重
              }
            });
            // this.averageAlarm = res.data[0]; //一般

            // this.majorAlarm = res.data[2]; //重大
            // this.specialAlarm = res.data[3]; //特别重大
          }
        }
      });
    },
    // 24小时实时负载统计
    queryLoadStatistic() {
      cockpitApi.queryLoadStatistic().then((res) => {
        if (res.code === 200) {
          let object = res.data[0];
          let xAxisData = [];
          let seriesData = [];
          for (const key in object) {
            xAxisData.push(key);
            if (object[key]) {
              seriesData.push(object[key]);
            } else {
              seriesData.push(`0`);
            }
          }
        }
      });
    },
    // 计划停电情况统计
    queryPowerCut() {
      // cockpitApi.queryPowerCut().then((res) => {
      //   if (res.code === 200) {
      // this.powerCut = res.data;
      // this.drawFailure(res.data);
      // }
      // });
    },
    // 计划停电情况统计
    queryPowerCut1() {
      cockpitApi.queryPowerCut().then((res) => {
        if (res.code === 200) {
          this.powerCut1 = res.data;
          this.drawFailure1(res.data);
        }
      });
    },
    // 电能质量情况统计
    queryPowerQuality() {
      cockpitApi.queryPowerQuality().then((res) => {
        if (res.code === 200) {
          let xAxisData = [];
          // 有功功率
          const activePower = res.data.activePower.map((item) => {
            let month = item.nowTime.slice(6, 7) + "月";
            xAxisData.push(month);
            return item.activePower;
          });
          // 视在功率
          const apparentPower = res.data.apparentPower.map((item) => {
            return item.apparentPower;
          });
          // 无功
          const reactivePower = res.data.reactivePower.map((item) => {
            return item.reactivePower;
          });
          this.drawDemand(xAxisData, apparentPower, activePower, reactivePower);
        }
      });
    },
    // 主要负荷设备统计
    queryRealData() {
      cockpitSecondApi.queryCountByUsageType().then((res) => {
        if (res.code === 200) {
          this.realData = res.data;
          // 获取总用电量（GWh）
          this.totalPower = `${res.data['1'].ratedPower.toFixed(2)}`;
        }
      });
    },
    // queryTotalPower() {
    //   cockpitApi.queryTotalPower().then((res) => {
    //     this.totalPower = `${res.toFixed(3)}`;
    //   });
    // },
    // 获取总用电量（GWh）
    queryDevice() {
      cockpitApi.queryDevice().then((res) => {
        if (res.code === 200) {
          const companyData = res.data.map((item) => {
            return item.companyName;
          });
          const before = res.data.map((item) => {
            return Number(item.before2012);
          });
          const between = res.data.map((item) => {
            return item.between2012And2019;
          });
          const after = res.data.map((item) => {
            return item.after2019;
          });
          this.drawDevice(companyData, before, between, after);
        }
      });
    },
    // 抢修/维修情况统计
    queryTaskStatistic() {
      cockpitApi.queryTaskStatistic().then((res) => {
        if (res.code === 200) {
          this.rushRepair = res.data[0];
          this.drawRushRepair(this.rushRepair);
          this.keepRepair = res.data[1];
          this.drawKeepRepair(this.keepRepair);
        }
      });
    },
    // 总电量峰谷平
    queryPeriodTime() {
      cockpitApi.queryPeriodTime({month: this.periodMonth}).then((res) => {
        if (res.code === 200) {
          let xAxisData = res.data.peakPower.map((item) => {
            let day = item.nowTime.slice(8, 10) + "日";
            return day;
          });
          xAxisData = xAxisData.slice(0, 24);
          // 尖
          let sharpSeries;
          if (res.data.tipPower) {
            sharpSeries = res.data.tipPower.map((item) => {
              return item.onceEpaddIns;
            });
          }
          // 峰
          let peakSeries = res.data.peakPower.map((item) => {
            return item.onceEpaddIns;
          });
          // 平
          let averageSeries = res.data.flatPower.map((item) => {
            return item.onceEpaddIns;
          });
          // 谷
          let valleySeries = res.data.valleyPower.map((item) => {
            return item.onceEpaddIns;
          });
        }
      });
    },
    nextWeek() {
      let array = [];
      var today = new Date();
      for (var i = 1; i <= 7; i++) {
        var pastDate = new Date(today.getTime() + i * 24 * 60 * 60 * 1000); // 计算 i 天前的日期
        var month = pastDate.getMonth() + 1; // 获取月份（月份从0开始，所以要加1）
        var day = pastDate.getDate(); // 获取日期
        // 格式化月份和日期，确保是两位数
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        array.push(month + '-' + day);
      }
      array.sort();
      return array
    },
    // 近七日用电趋势
    queryWeekElectricity() {
      cockpitApi.queryWeekElectricity().then((res) => {
        if (res.code === 200) {
          let xAxisData = this.nextWeek();
          let seriesData = res.data.map((item) => {
            return item.onceEpaddIns;
          });
          this.drawTrend();
        }
      });
    },
    // 抢修情况统计
    drawRushRepair(rushRepair) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#40ECC2", "#F6CD29"],

        series: [
          {
            name: "抢修情况统计",
            type: "pie",
            width: "64%",
            height: "64%",
            radius: ["55%", "70%"],
            center: ["45%", "60%"], // 调整饼图位置
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {value: rushRepair.taskCompleted, name: "已完成"},
              {value: rushRepair.taskUnfinished, name: "未完成"},
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts
          .init(document.getElementById("rushRepairChart"))
          .setOption(option);
    },
    // 维修情况统计
    drawKeepRepair(keepRepair) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#40ECC2", "#F6CD29"],
        series: [
          {
            name: "维修情况统计",
            type: "pie",
            width: "64%",
            height: "64%",
            radius: ["55%", "70%"],
            center: ["34%", "60%"], // 调整饼图位置
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {value: keepRepair.taskCompleted, name: "已完成"},
              {value: keepRepair.taskUnfinished, name: "未完成"},
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts
          .init(document.getElementById("keepRepairChart"))
          .setOption(option);
    },
    // 近七日用电趋势
    drawTrend(powerCut) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        title: {
          text: '负荷用能',
          x: 'center',
          y: '102px',
          textStyle: {
            fontWeight: 'normal',
            color: "#aed6ff",
            fontSize: 12
          }
        },
        series: [
          {
            name: "负荷用能",
            type: "pie",
            width: "100%",
            height: "100%",
            radius: ["55%", "70%"],
            center: ["50%", "50%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                // value: powerCut.onInPlan,
                value: powerCut['1'].energy,
                name: "充电桩",
                itemStyle: {color: "#2ee1b2"},
              },
              {
                // value: powerCut.noStartePlan,
                value: powerCut['2'].energy,
                name: "空调",
                itemStyle: {color: '#18bfe9'},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['3'].energy,
                name: "空压机",
                itemStyle: {color: "#3775ee"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['4'].energy,
                name: "电动机",
                itemStyle: {color: "#0e49bb"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['5'].energy,
                name: "照明",
                itemStyle: {color: "#f0c025"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['6'].energy,
                name: "电梯",
                itemStyle: {color: "#ec4c3b"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['7'].energy,
                name: "展厅设备",
                itemStyle: {color: "#e552ff"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['99'].energy,
                name: "其他",
                itemStyle: {color: "#be0000"},
              },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("trendChart")).setOption(option);
    },
    // 电能质量情况统计
    drawDemand(xAxisData, apparentPower, activePower, reactivePower) {
      const demandChart = echarts.init(document.getElementById("demandChart"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        title: {
          text: '87.5%', // 主标题文本
          subtext: '消缺率', // 副标题文本
          // 主标题样式
          x: 'center',
          y: '80px',
          textStyle: {
            color: '#aed6ff', // 主标题颜色
            fontSize: 18, // 主标题字体大小
            lineHeight: 10
          },
          // 副标题样式
          subtextStyle: {
            color: '#aed6ff', // 副标题颜色
            fontSize: 12, // 副标题字体大小
            lineHeight: 10
          }
        },
        series: [
          {
            name: "消缺率",
            type: "pie",
            width: "100%",
            height: "100%",
            radius: ["55%", "70%"],
            center: ["50%", "50%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                // value: powerCut.onInPlan,
                value: 25,
                name: "缺陷数",
                itemStyle: {color: "#40ECC2"},
              },
              {
                // value: powerCut.noStartePlan,
                value: 25,
                name: "消缺数",
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#4683FF",
                    },
                    {
                      offset: 1,
                      color: "#2D54A4",
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      // demandChart.setOption(option);
      // 使用刚指定的配置项和数据显示图表。
      demandChart.setOption(option);
    },
    drawConsumption(data) {
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = data.timePeriods
      let arr1 = []
      let arr2 = []
      let arr3 = []
      let arr4 = []
      let arr5 = []
      let arr6 = []
      let arr7 = []
      let arr99 = []
      data.statHourly['1'].forEach((item) => {
        arr1.push(item.energy)
      })
      data.statHourly['2'].forEach((item) => {
        arr2.push(item.energy)
      })
      data.statHourly['3'].forEach((item) => {
        arr3.push(item.energy)
      })
      data.statHourly['4'].forEach((item) => {
        arr4.push(item.energy)
      })
      data.statHourly['5'].forEach((item) => {
        arr5.push(item.energy)
      })
      data.statHourly['6'].forEach((item) => {
        arr6.push(item.energy)
      })
      data.statHourly['7'].forEach((item) => {
        arr7.push(item.energy)
      })
      data.statHourly['99'].forEach((item) => {
        arr99.push(item.energy)
      })
      var option = {
        title: {
          text: '电量: kWh    {j|} 尖  {f|} 峰  {p|} 平  {g|} 谷',
          top: '24',
          left: '30',
          textStyle: {
            color: '#ffffff',
            fontSize: '12',
            rich: {
              'j': {
                backgroundColor: 'rgba(225,66,66,.3)',
                width: 8,
                height: 8
              },
              'f': {
                backgroundColor: 'rgba(225,199,75,.3)',
                width: 8,
                height: 8
              },
              'p': {
                backgroundColor: 'rgba(61,226,188,.3)',
                width: 8,
                height: 8
              },
              'g': {
                backgroundColor: 'rgba(58,109,212,.3)',
                width: 8,
                height: 8
              }
            }
          }
        },
        tooltip: {
          trigger: "axis",
          confine: true
        },
        grid: {
          right: "5%",
          bottom: '58'
        },
        legend: {
          icon: "square",
          itemWidth: 6,
          itemHeight: 6,
          right: "15%",
          top: "2px",
          textStyle: {
            color: "#C5D8FF",
          },
        },
        xAxis: {
          // name: '(日)',
          nameLocation: 'end',
          type: 'category',
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
          nameTextStyle: {
            color: "#C5D8FF",
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          // name: "电量:kWh",
          nameTextStyle: {
            color: "#C5D8FF",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
            formatter: function (value) {
              return Math.abs(value); // 格式化为固定的货币符号和小数点后两位
            }
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "充电桩",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#2ee1b2'
            },
            data: arr1,
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            }
          },
          {
            name: "空调",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#18bfe9'
            },
            data: arr2,
          },
          {
            name: "空压机",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#3775ee'
            },
            data: arr3,
          },
          {
            name: "电动机",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#0e49bb'
            },
            data: arr4,
          },
          {
            name: "照明",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#f0c025'
            },
            data: arr5,
          },
          {
            name: "电梯",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#ec4c3b'
            },
            data: arr6,
          },
          {
            name: "展厅设备",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#e552ff'
            },
            data: arr7,
          },
          {
            name: "其他",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#be0000'
            },
            data: arr99,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts
          .init(document.getElementById("consumptionChart"))
          .setOption(option);
    },
    //各中心新旧设备占比
    drawDevice(companyData, before, between, after) {
      var option = {
        tooltip: {},
        legend: {
          icon: "square",
          itemWidth: 6,
          itemHeight: 6,
          right: "4%",
          textStyle: {
            color: "#C5D8FF",
          },
        },
        grid: {
          bottom: 66,
        },
        xAxis: {
          data: companyData,
          axisLabel: {
            textStyle: {
              fontSize: 10,
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          name: "单位:个",
          nameTextStyle: {
            color: "#C5D8FF",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "2012以前",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#FF6A24"},
                {offset: 1, color: "#FF8C56"},
              ]),
            },
            data: before,
          },
          {
            name: "2012-2019",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#CEA500"},
                {offset: 1, color: "#EDC42F"},
              ]),
            },
            data: between,
          },
          {
            name: "2019-今",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#3DE2BC"},
                {offset: 1, color: "#3DE2BC"},
              ]),
            },
            data: after,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("deviceChart")).setOption(option);
    },
    //24小时实时负载统计
    drawLoad(data, year, lastYear) {
      let lastArr = []
      let yearArr = []
      data[lastYear].forEach(l => {
        lastArr.push(l.unitConsumption)
      })
      data[year].forEach(y => {
        yearArr.push(y.unitConsumption)
      })
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "square",
          itemWidth: 6,
          itemHeight: 6,
          right: "4%",
          top: 30,
          textStyle: {
            color: "#C5D8FF",
            fontSize: 12
          },
        },
        grid: {
          bottom: 66,
          left: 60,
          right: 30
        },
        xAxis: {
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          name: "单位:kWh/万元",
          nameTextStyle: {
            fontSize: 12,
            color: "#C5D8FF",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: `${lastYear}产值单耗`,
            type: "line",
            showSymbol: false,
            lineStyle: {
              color: "#e7be26", // 可选，设置线条颜色
              width: 2,
            },
            areaStyle: {
              opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(231,190,38,0.8)",
                },
                {
                  offset: 1,
                  color: "rgba(231,190,38,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: lastArr,
          },
          {
            name: `${year}产值单耗`,
            type: "line",
            showSymbol: false,
            lineStyle: {
              color: "#37c9ad", // 可选，设置线条颜色
              width: 2,
            },
            areaStyle: {
              opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(55,201,173,0.8)",
                },
                {
                  offset: 1,
                  color: "rgba(55,201,173,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: yearArr,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("loadChart")).setOption(option);
    },
    // 计划外停电情况统计
    drawFailure(powerCut) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        title: {
          text: '负荷用能费用',
          x: 'center',
          y: '100px',
          textStyle: {
            fontWeight: 'normal',
            color: "#aed6ff",
            fontSize: 12
          }
        },
        series: [
          {
            name: "负荷用能费用",
            type: "pie",
            width: "100%",
            height: "100%",
            radius: ["55%", "70%"],
            center: ["50%", "50%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                // value: powerCut.onInPlan,
                value: powerCut['1'].cost,
                name: "充电桩",
                itemStyle: {color: "#2ee1b2"},
              },
              {
                // value: powerCut.noStartePlan,
                value: powerCut['2'].cost,
                name: "空调",
                itemStyle: {color: '#18bfe9'},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['3'].cost,
                name: "空压机",
                itemStyle: {color: "#3775ee"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['4'].cost,
                name: "电动机",
                itemStyle: {color: "#0e49bb"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['5'].cost,
                name: "照明",
                itemStyle: {color: "#f0c025"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['6'].cost,
                name: "电梯",
                itemStyle: {color: "#ec4c3b"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['7'].cost,
                name: "展厅设备",
                itemStyle: {color: "#e552ff"},
              },
              {
                // value: powerCut.terminationPlan,
                value: powerCut['99'].cost,
                name: "其他",
                itemStyle: {color: "#be0000"},
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("failureChart")).setOption(option);
    },
    drawFailure1(powerCut) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "计划停电情况",
            type: "pie",
            width: "80%",
            height: "80%",
            radius: ["55%", "70%"],
            center: ["32%", "57%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                value: powerCut.onInPlan,
                name: "空压机",
                itemStyle: {color: "#40ECC2"},
              },
              {
                value: powerCut.noStartePlan,
                name: "充电桩",
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#4683FF",
                    },
                    {
                      offset: 1,
                      color: "#2D54A4",
                    },
                  ]),
                },
              },
              {
                value: powerCut.terminationPlan,
                name: "生产设备",
                itemStyle: {color: "#F6CD29"},
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("failureChart1")).setOption(option);
    },

    getCenter(center) {
      let stationData = this.stationData.map((item) => {
        let mark = {};
        mark.id = item.id;
        mark.name = item.stationName;
        mark.type = "station";
        mark.value = [item.longitude, item.latitude];
        return mark;
      });
      this.mapChart = echarts.init(document.getElementById("mapChart"));
      var option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "jingjinji",
          roam: true,
          zoom: 1.04,
          scaleLimit: {
            min: 1.04,
          },
          animationDurationUpdate: 0,
          top: 20,
          bottom: 20,
          tooltip: {
            show: false,
          },
          label: {
            show: false,
          },
          itemStyle: {
            normal: {
              borderColor: "#3095ff",
              areaColor: "040a36",
              borderWidth: 2, // 设置边缘线宽度为1
            },
          },
        },
        series: [
          {
            name: "station",
            type: "scatter",
            coordinateSystem: "geo",
            symbol:
                "image://" +
                require("@/assets/images/electricity/coordinate.png"),
            symbolSize: 32,
            data: stationData,
          },
        ],
      };
      option.geo.center = center;
      option.geo.zoom = 3;
      mapChart.setOption(option);
    },
    // 地图
    drawMap(type) {
      let stationData = this.stationData.map((item) => {
        let mark = {};
        mark.id = item.id;
        mark.name = item.stationName;
        mark.type = "station";
        mark.value = [item.longitude, item.latitude];
        return mark;
      });
      console.log("场站数据", stationData);
      const _this = this;

      // 销毁实例的写法
      if (this.mapChart) {
        this.mapChart.dispose();
      }
      this.mapChart = echarts.init(document.getElementById("mapChart"));

      // let mapChart = echarts.init(document.getElementById("mapChart"));
      echarts.registerMap("jingjinji", jingjinji);
      var option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "jingjinji",
          roam: true,
          zoom: 1.04,
          scaleLimit: {
            min: 1.04,
          },
          animationDurationUpdate: 0,
          top: 20,
          bottom: 20,
          tooltip: {
            show: false,
          },
          label: {
            show: false,
          },
          itemStyle: {
            normal: {
              borderColor: "#3095ff",
              areaColor: "040a36",
              borderWidth: 2, // 设置边缘线宽度为1
            },
          },
        },
        series: [
          {
            name: "station",
            type: "scatter",
            coordinateSystem: "geo",
            symbol:
                "image://" +
                require("@/assets/images/electricity/coordinate.png"),
            symbolSize: 32,
            data: stationData,
          },
        ],
      };
      if (this.coordinate.length !== 0) {
        console.log("中心点", this.coordinate);
        option.geo.center = this.coordinate;
        option.geo.zoom = 3;
        if (type && type === "search") {
          cockpitSecondApi.stationDetail(this.stationId)
              .then((res) => {
                if (res.code === 200) {
                  this.stationDialog = true;
                  this.stationInfo = res.data;
                }
              });
        }
      }

      this.mapChart.setOption(option);
      console.log("配置项", option);
      //点击事件
      this.mapChart.on("click", function (params) {
        _this.stationDialog = false;
        if (params.data) {
          _this.stationId = params.data.id;
          cockpitSecondApi
              .stationDetail(params.data.id)
              .then((res) => {
                if (res.code === 200) {
                  _this.coordinate = params.data.value;
                  _this.drawMap();
                  _this.stationDialog = true;
                  _this.stationInfo = res.data;
                }
              });
        }
      });
      this.mapChart.on("geoRoam", function () {
        _this.stationDialog = false;
      });
    },
    //主要负荷设备统计
    getByUsageType() {
      cockpitSecondApi.byUsageType().then(res => {
        if (res.code == '200') {
          this.realData = res.data;
          // 获取总用电量（GWh）
          this.totalPower = `${Number(res.data.total.totalEnergy / 1000000000).toFixed(2)}`;
        }
      })
    },
    getByType() {
      const param = {
        month: '2024-09-01'
      }
      cockpitSecondApi.byType(param).then(res => {
        if (res.code == '200') {
          this.byTypeObj = res.data
          this.drawFailure(res.data)
          this.drawTrend(res.data)
        }
      })
    },
    getByPowerTypeAndType() {
      const param = {
        month: '2024-09-01'
      }
      cockpitSecondApi.byPowerTypeAndType(param).then(res => {
        if (res.code == '200') {
          this.byPowerTypeAndTypeObj = res.data['1']
          this.byPowerTypeAndTypeObj1 = res.data['2']
          this.drawLastMonthRate(res.data['1'])
          this.drawLastMonthRate1(res.data['2'])
        }
      })
    },
    //上月能耗占比（交流）
    drawLastMonthRate(data) {
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "负荷用能费用",
            type: "pie",
            width: "100%",
            height: "100%",
            radius: ["60%", "80%"],
            center: ["50%", "50%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                // value: powerCut.onInPlan,
                value: data['1'].energy,
                name: "充电桩",
                itemStyle: {color: "#2ee1b2"},
              },
              {
                // value: powerCut.noStartePlan,
                value: data['2'].energy,
                name: "空调",
                itemStyle: {color: '#18bfe9'},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['3'].energy,
                name: "空压机",
                itemStyle: {color: "#3775ee"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['4'].energy,
                name: "电动机",
                itemStyle: {color: "#0e49bb"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['5'].energy,
                name: "照明",
                itemStyle: {color: "#f0c025"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['6'].energy,
                name: "电梯",
                itemStyle: {color: "#ec4c3b"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['7'].energy,
                name: "展厅设备",
                itemStyle: {color: "#e552ff"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['99'].energy,
                name: "其他",
                itemStyle: {color: "#be0000"},
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("lastMonthRate")).setOption(option);
    },
    //上月能耗占比（直流）
    drawLastMonthRate1(data) {
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "负荷用能费用",
            type: "pie",
            width: "100%",
            height: "100%",
            radius: ["60%", "80%"],
            center: ["50%", "50%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                // value: powerCut.onInPlan,
                value: data['1'].energy,
                name: "充电桩",
                itemStyle: {color: "#2ee1b2"},
              },
              {
                // value: powerCut.noStartePlan,
                value: data['2'].energy,
                name: "空调",
                itemStyle: {color: '#18bfe9'},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['3'].energy,
                name: "空压机",
                itemStyle: {color: "#3775ee"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['4'].energy,
                name: "电动机",
                itemStyle: {color: "#0e49bb"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['5'].energy,
                name: "照明",
                itemStyle: {color: "#f0c025"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['6'].energy,
                name: "电梯",
                itemStyle: {color: "#ec4c3b"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['7'].energy,
                name: "展厅设备",
                itemStyle: {color: "#e552ff"},
              },
              {
                // value: powerCut.terminationPlan,
                value: data['99'].energy,
                name: "其他",
                itemStyle: {color: "#be0000"},
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("lastMonthRate2")).setOption(option);
    },
    //产值单耗趋势
    getByYears() {
      let year = new Date().getFullYear()
      let lastYear = year - 1
      let arr = [lastYear, year]
      const param = {
        years: arr.join(',')
      }
      cockpitSecondApi.byYears(param).then(res => {
        if (res.code == '200') {
          this.drawLoad(res.data, year, lastYear)
        }
      })
    },
    getUnitConsumption() {
      let year = new Date().getFullYear()
      let lastYear = year - 1
      let arr = [lastYear, year]
      const param = {
        years: arr.join(',')
      }
      cockpitSecondApi.unitConsumption(param).then(res => {
        if (res.code == '200') {
          this.nowYear = res.data[year].toFixed(2)
          this.lastYear = res.data[lastYear].toFixed(2)
          this.nowYearNum = year
          this.lastYearNum = lastYear
        }
      })
    },
    getStationsAll() {
      cockpitSecondApi.stationsAll().then(res => {
        if (res.code == '200') {
          this.energySavingRate = res.data.energySavingRate.toFixed(2)
          this.targetAchievementRate = res.data.targetAchievementRate.toFixed(2)
        }
      })
    },
    getHourly() {
      const param = {
        stationId: '4',
        day: '2024-10-22',
        // day: this.periodMonth
      }
      cockpitSecondApi.hourly(param).then(res => {
        this.drawConsumption(res.data);
      })
    },
    getQueryStation(){
      let params = {
        companyId: this.branchId,
        propertyUnit: this.propertyId,
        stationName: this.stationName,
      };
      cockpitSecondApi.queryStation(params).then((res) => {
        this.branchData = res.data.companyInfos;
        this.propertyData = res.data.optCompanyInfos;
        this.stationData = res.data.stationInfos;
        this.drawMap();
      });
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>
<style scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.el-date-editor {
  width: 106px;
  border: 1px solid #0e447e;
}

.el-date-editor :deep() .el-icon-date {
  color: #aed6ff;
}

.el-date-editor :deep() .el-input__inner {
  width: 100%;
}

.el-date-editor :deep() .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.25rem;
  width: 0.8rem;
  border: none;
  font-weight: 400;
  font-size: 12px;
  color: #d9e3ff;
}

.el-date-editor :deep() .el-input__icon {
  height: 40px;
  position: relative;
  top: -7px;
}

#electricity {
  width: 1920px;
  height: 1080px;
  background: url("../../assets/images/electricity/background.jpg") no-repeat center;
  background-size: cover;
  font-size: 16px;
  box-shadow: 0px 1px 1px 0px rgba(12, 24, 58, 0.69);
  padding-bottom: 24px;
  box-sizing: border-box;
  position: relative;
}

.header {
  width: 1920px;
  height: 110px;
  background: url("../../assets/images/electricity/header-background.png") no-repeat center;
  background-size: cover;
  padding: 15px 21px 0 29px;
  box-sizing: border-box;
  position: relative;
}

.nav {
  display: flex;
}

.nav li {
  height: 20px;
  display: flex;
  align-items: center;
}

.home-page img {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.home-page,
.exit {
  cursor: pointer;
}

.date img {
  margin: 0 10px 0 19px;
}

.nav span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

/* 标题 */
.title {
  height: 42px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 22px;
  left: 691px;
}

.title span {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin-left: 12px;
}

/* 操作 */
.operate {
  display: flex;
}

.operate li {
  height: 28px;
  display: flex;
  align-items: center;
}

.address span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8d9ac7;
  margin: 0 24px 0 11px;
}

.user span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e0e8ff;
  margin: 0 10px 0 7px;
}

.line {
  width: 1px;
  height: 21px;
  background-color: rgb(71, 50, 95);
  margin-right: 12px;
}

.exit span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  margin-left: 8px;
}

/* main */
.main {
  display: flex;
}

/* 左边 */
.content-left {
  width: 514px;
  padding-left: 30px;
  box-sizing: border-box;
  margin-top: -18px;
}

.subtitle {
  height: 24px;
  display: flex;
  /* align-items: center; */
}

.subtitle img {
  width: 32px;
  height: 15px;
  margin-top: 3px;
}

.subtitle p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c5d8ff;
}

.subtitle span {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #c5d8ff;
}

/* 实时数据 */
.now-data {
  margin-top: 16px;
}

.now-data li {
  position: relative;
}

.station span,
.transformer span {
  font-size: 28px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f6cd29;
}

.transformer div,
.station div {
  position: absolute;
  top: 15px;
  left: 95px;
}

.station p,
.transformer p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c5d8ff;
  margin-top: -4px;
}

.task {
  position: relative;
}

.task span {
  font-size: 28px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #40ecc2;
}

.task p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c5d8ff;
  margin-top: -4px;
}

.daily-tasks {
  position: absolute;
  top: 24px;
  left: 95px;
}

.daily-tasks {
  position: absolute;
  top: 24px;
  left: 95px;
}

.daily-tasks {
  position: absolute;
  top: 24px;
  left: 95px;
}

.current-plan {
  position: absolute;
  top: 24px;
  left: 237px;
}

.completion-rate {
  position: absolute;
  top: 24px;
  left: 381px;
}

/* 故障消缺情况统计 */
.fault {
  width: 484px;
  height: 235px;
  background: url("../../assets/images/electricity/fault-load-background.png") no-repeat center;
  background-size: cover;
  margin-top: 12px;
  position: relative;
}

#keepRepairChart,
#rushRepairChart {
  width: 484px;
  height: 218px;
}

.fault-legend {
  position: absolute;
  top: 69px;
}

.rush-repair {
  left: 125px;
}

.keep-repair {
  right: 22px;
}

.rush-total {
  left: 38px;
}

.repair-total {
  right: 159px;
}

.task-name {
  font-size: 16px;
  font-weight: 400;
  color: #c5d8ff;
  position: absolute;
  bottom: 24px;
}

.task-total {
  width: 64px;
  text-align: center;
  position: absolute;
  top: 78px;
}

.task-total div {
  font-size: 14px;
  font-weight: 400;
  color: #c5d8ff;
}

.task-total .num {
  font-size: 24px;
  font-family: DIN;
  font-weight: 400;
  color: #f6cd29;
}

.fault-legend li {
  width: 105px;
  height: 35px;
  background: url("../../assets/images/electricity/fault-legend.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.fault-legend li:first-child {
  margin-bottom: 7px;
}

.fault-legend li div {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin: 0 8px;
}

.fault-legend li p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
}

.trend {
  width: 484px;
  height: 235px;
  background: url("../../assets/images/electricity/fault-load-background.png") no-repeat center;
  background-size: cover;
  margin-top: 15px;
}

#trendChart {
  width: 213px;
  height: 191px;
  margin: 14px 0 0 18px;
  background: url("../../assets/images/electricity/trendChart-bg.png") no-repeat center / 100%;
}

.demand {
  width: 484px;
  height: 235px;
  background: url("../../assets/images/electricity/fault-load-background.png") no-repeat center;
  background-size: cover;
  margin-top: 15px;
  position: relative;
}

#demandChart {
  width: 213px;
  height: 191px;
  margin: 14px 0 0 18px;
  background: url("../../assets/images/electricity/demandChart-bg.png") no-repeat center / 100%;
}

/* 中 */
.middle {
  width: 891px;
  box-sizing: border-box;
  position: relative;
}

.map {
  width: 800px;
  height: 700px;
}

#mapChart {
  width: 900px;
  height: 700px;
}

.total-electricity {
  position: absolute;
  top: 37px;
  left: 247px;
  z-index: 3;
}

.total-electricity .text {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-left: 11px;
}

.total {
  display: flex;
  margin-top: 16px;
}

.total li {
  width: 41px;
  height: 72px;
  background: url("../../assets/images/electricity/number-background.png") no-repeat center;
  background-size: cover;
  margin-right: 13px;
  font-size: 50px;
  font-family: DIN;
  font-weight: 400;
  color: #e9f1fa;
  text-align: center;
  line-height: 72px;
}

.total li:last-child {
  margin-right: 0;
}

.pop-ups {
  width: 265px;
  height: 369px;
  background: url("../../assets/images/electricity/eject-background.png") no-repeat center;
  background-size: cover;
  padding-top: 25px;
  box-sizing: border-box;
  position: fixed;
  top: 417px;
  right: 677px;
}

.dialog-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin: 0 0 17px 28px;
}

.dialog-title img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.dialog-title p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
}

.dialog-form {
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 9px;
  box-sizing: border-box;
  padding-left: 24px;
}

.dialog-form div::after {
  content: ":";
  margin: 0 3px;
}

.year-electricity {
  width: 212px;
  height: 70px;
  background: url("../../assets/images/electricity/year-background.png") no-repeat center;
  background-size: cover;
  margin: 0 29px 13px 20px;
  position: relative;
}

.year-electricity div {
  position: absolute;
  top: 12px;
  left: 69px;
}

.year-electricity span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #40ecc2;
}

.year-electricity p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
}

.dialog-content button {
  width: 206px;
  height: 38px;
  background: #1c479e;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  text-align: center;
  line-height: 38px;
  margin-left: 16px;
  border: 0;
  cursor: pointer;
}

.coordinate {
  position: absolute;
  top: 312px;
  left: 340px;
}

.arrow {
  width: 228px;
  height: 84px;
  background: url("../../assets/images/electricity/arrow.png") no-repeat center;
  background-size: cover;
  margin-right: 4px;
  position: fixed;
  top: 379px;
  right: 792px;
  pointer-events: none;
}

.station-info {
}

.consumption {
  width: 850px;
  height: 250px;
  background: url("../../assets/images/electricity/consumption-background.png") no-repeat center;
  background-size: cover;
  position: absolute;
  bottom: 17px;
  left: 535px;
  overflow: hidden;
  z-index: 10;
}

#consumptionChart {
  width: 850px;
  height: 250px;
  z-index: 9;
}

.content-right {
  width: 484px;
  padding-right: 31px;
  box-sizing: border-box;
  margin-top: -18px;
}

.accident {
  margin-top: 16px;
}

.accident li {
  position: relative;
  margin: 0 20px 4px 0;
}

.accident li:nth-child(even) {
  margin-right: 0;
}

.accident li div {
  position: absolute;
  top: 12px;
  left: 86px;
}

.accident span {
  font-size: 32px;
  font-family: Bahnschrift;
  font-weight: 400;
}

.accident p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c5d8ff;
  margin-top: -4px;
}

.failure {
  width: 484px;
  height: 316px;
  background: url("../../assets/images/electricity/lastMonthRate-bg.png") no-repeat center;
  background-size: cover;
  margin-top: 8px;
  position: relative;
}

#failureChart {
  width: 213px;
  height: 191px;
  margin: 14px 0 0 18px;
  background: url("../../assets/images/electricity/failureChart-bg.png") no-repeat center / 100%;
}

.failure-legend {
  height: 170px;
  position: absolute;
  top: 42px;
  left: 252px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 4px; /* 垂直滚动条的宽度 */
  height: 4px; /* 水平滚动条的高度 */
}

::-webkit-scrollbar-track {
  background: #0c1c3c; /* 轨道颜色 */
}

::-webkit-scrollbar-thumb {
  background: #0484dc; /* 滑块颜色 */
}

::-webkit-scrollbar-thumb:hover {
  background: #0180d9; /* 滑块hover颜色 */
}


.failure-legend li {
  width: 194px;
  height: 35px;
  background: url("../../assets/images/electricity/fault-legend.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.lastMonthRate-legend {
  overflow-y: scroll;
  height: 150px;
}

.lastMonthRate-legend li {
  width: 101px;
  height: 43px;
  background: url("../../assets/images/electricity/last-month-txt-bg.png") no-repeat center;
  background-size: cover;
  align-items: center;
  margin-bottom: 7px;
  font-size: 12px;
  color: #aed6ff;
  padding-left: 14px;
  box-sizing: border-box;
  line-height: 16px;
}

.lastMonthRate-legend .failure-square {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: linear-gradient(0deg, #4683ff, #2d54a4);
  border-radius: 2px;
}

.demand-legend {
  position: absolute;
  top: 66px;
  left: 252px;
}

.demand p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
}

.demand-legend li {
  width: 174px;
  height: 35px;
  padding-left: 42px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.demand-legend li.qxs {
  background: url("../../assets/images/electricity/qxs.png") no-repeat left center / 100%;
}

.demand-legend li.xqs {
  background: url("../../assets/images/electricity/xqs.png") no-repeat left center / 100%;
}

.demand-bottom {
  width: 234px;
  margin-left: -26px;
  margin-top: 20px;
  display: flex;
  font-size: 12px;
  color: #bccaf3;
  line-height: 18px;
}

.demand-bottom > div {
  flex: 1;
  padding-left: 44px;
}

.device-rate {
  background: url("../../assets/images/electricity/device-rate.png") no-repeat left center / 40px;
}

.device-state {
  background: url("../../assets/images/electricity/device-state.png") no-repeat left center / 40px;
}

.demand p.title-state {
  font-size: 14px;
  color: #40ecc2;
}

.demand p.title-rate {
  font-size: 14px;
  color: #51a1ff;
}

.failure-legend .failure-square {
  width: 8px;
  height: 8px;
  background: linear-gradient(0deg, #4683ff, #2d54a4);
  border-radius: 2px;
  margin: 0 10px 0 10px;
}

.failure-legend li:last-child {
  margin-bottom: 0;
}

.failure-legend li:last-child div {
  background: linear-gradient(0deg, #ff6a24, #ff8c56);
}

.failure-legend p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
}

/* 计划停电 */
.failure-total {
  display: flex;
  align-items: center;
}

.failure-total img {
  width: 5px;
  height: 5px;
  margin: 0 6px 0 9px;
}

.failure-total span {
  font-size: 32px;
  font-family: DIN;
  font-weight: 400;
  color: #f6cd29;
}

.failure-total p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a7cdec;
}

.load {
  width: 484px;
  height: 420px;
  background: url("../../assets/images/electricity/load-new-background.png") no-repeat center;
  background-size: cover;
  margin: 10px 0;
}

#loadChart {
  width: 484px;
  height: 420px;
}

.device {
  width: 484px;
  height: 250px;
  background: url("../../assets/images/electricity/device-background.png") no-repeat center;
  background-size: cover;
  margin-top: 28px;
}

#deviceChart {
  width: 484px;
  height: 250px;
}

/* 下拉菜单 */
.select {
  width: 60px;
  height: 23px;
  background: rgba(41, 88, 164, 0.34);
  border-radius: 2px;
  display: flex;
  cursor: pointer;
}

.select span {
  width: 37px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
  border-right: 1px solid #040941;
  line-height: 23px;
  text-align: center;
}

.select p {
  width: 22px;
  text-align: center;
}

.menu-content {
  width: 60px;
  height: 100px;
  background-color: #10225a;
  margin-top: 2px;
  padding: 4px 0;
  box-sizing: border-box;
  overflow-y: scroll;
  display: none;
}

.dropdown-menu:hover .menu-content {
  display: block;
}

.menu-content li {
  font-size: 12px;
  font-family: Microsoft YaHei;
  color: #c5d8ff;
  text-align: center;
}

::v-deep .menu-content::-webkit-scrollbar {
  width: 4px;
  /* 设置滚动条高度 */
  background-color: #091856;
  /* 设置滚动条背景色 */
}

::v-deep .menu-content::-webkit-scrollbar-thumb {
  background-color: #193f92;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.dialog-title {
  display: flex;
  margin: 0 26px 21px 28px;
}

.dialog-title img {
  width: 18px;
  height: 18px;
  margin: 4px 8px 0 0;
}

.dialog-title p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
}

.dialog-content {
  height: 220px;
  margin: 21px 0 0 15px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.dialog-content li {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 12px;
}

.dialog-content div::after {
  content: ":";
  margin: 0 3px;
}

.dialog-content .year-GWh::after {
  content: "";
}

.weather {
  position: absolute;
  right: 31px;
  top: 51px;
}

.weather li {
  width: 51px;
  height: 67px;
  margin-bottom: 16px;
}

.bus-stop {
  width: 52px;
  height: 67px;
  background: url("../../assets/images/cockpit/bus-stop.png") no-repeat center;
  background-size: cover;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 41px;
  position: absolute;
  top: 25px;
  left: 20px;
  z-index: 4;
}

#chart {
  width: 423px;
  height: 263px;
  margin-top: -20px;
}

.dialog {
  width: 690px;
  height: 714px;
  background: url("../../assets/images/dialog/background.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 32%;
  top: 12.5%;
  padding: 19px 19px 0 25px;
  box-sizing: border-box;
  z-index: 100;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.close img {
  width: 11px;
  height: 11px;
}

.screen-title {
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
  text-align: center;
}

.screen-subtitle {
  display: flex;
  align-items: center;
  margin: 12px 0 13px 0;
}

.screen-subtitle img {
  width: 20px;
  height: 20px;
}

.screen-subtitle span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-left: 16px;
}

.screen-content li {
  width: 150px;
  height: 30px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  background-color: rgba(14, 100, 217, 0.11);
  margin: 0 7px 8px 0;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  /* 确保文本不会换行 */
  overflow: hidden;
  /* 隐藏超出容器的文本 */
  text-overflow: ellipsis;
  /* 当文本超出容器时显示省略号 */
}

.screen-content {
  height: 1.5rem;
  overflow: auto;
  width: 6.5rem;
  /* display: flex;
  flex-wrap: wrap; */
}

.screen-content::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.screen-content::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.screen-content::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.station-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 13px 0;
}

.station-title .screen-subtitle {
  margin: 0;
}

.search {
  display: flex;
}

.search :deep() input {
  width: 480px;
  height: 30px;
  background-color: rgba(14, 100, 217, 0.11);
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: 0;
  box-sizing: border-box;
}

.search :deep() button {
  width: 75px;
  height: 30px;
  background-color: #176ad2;
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin-left: -10px;
}

.screen-content .all-station {
  width: 205px;
}

.dialog-footer {
  margin: 7px 0 17px 0;
  display: flex;
  justify-content: center;
}

.dialog-footer button {
  width: 76px;
  height: 32px;
  background-color: #176ad2;
  color: #ffffff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: none;
}

.dialog-footer button:first-child {
  margin-right: 15px;
  background-color: #092259;
  color: #aed6ff;
}

.el-date-editor :deep() .el-input__inner {
  width: 100% !important;
}

.el-date-editor {
  width: 105px;
  position: absolute;
  right: 14px;
  top: 22px;
  z-index: 10;
  border: 1px solid #0e447e;
}

/* 修改日期选择器样式 */
::v-deep .el-icon-date {
  color: #aed6ff;
}

:deep() .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.25rem;
  width: 0.8rem;
  border: none;
  font-weight: 400;
  font-size: 12px;
  color: #d9e3ff;
}

:deep() .el-input__icon {
  height: 40px;
  position: relative;
  top: -7px;
}
</style>
<style lang="css">
/*  */
.echarts-picker {
  background: #041c4c;
  color: #aed6ff;
  border-color: #041c4c;
}

.echarts-picker .el-picker-panel__body .el-date-picker__header-label {
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__icon-btn {
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-year-table .cell {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-year-table .today .cell {
  background: #041c4c;
  color: #409eff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-month-table .cell {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-month-table .today .cell {
  background: #041c4c;
  color: #409eff;
}

.echarts-picker .el-picker-panel__body .el-date-table .disabled div {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker[x-placement^="bottom"] .popper__arrow::after {
  top: 0 !important;
  border-bottom-color: #041c4c !important;
}

.echarts-picker[x-placement^="top"] .popper__arrow::after {
  bottom: 0 !important;
  border-top-color: #041c4c !important;
}

.consumption-chart-icon {
  position: absolute;
  left: 190px;
}

.consumption-chart-icon li {
  display: inline-block;
  margin-left: 10px;
  color: #c5d8ff;
  font-size: 14px;
}

.consumption-chart-icon li:before {
  content: "■";
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 2px;
  margin-right: 6px;
}

.consumption-chart-icon li.power-top:before {
  color: rgba(255, 66, 66, 0.3);
}

.consumption-chart-icon li.power-peak:before {
  color: rgba(255, 199, 75, 0.3);
}

.consumption-chart-icon li.power-flat:before {
  color: rgba(61, 226, 188, 0.3);
}

.consumption-chart-icon li.power-valley:before {
  color: rgba(58, 109, 212, 0.3);
}

#lastMonthRate, #lastMonthRate2 {
  width: 100%;
  height: 120px;
  margin-left: -10px;
}

.ac-con {
  height: 60px;
  box-sizing: border-box;
  padding-left: 76px;
  background: url("../../assets/images/electricity/ac-icon.png") no-repeat left 6px center / 60px;
  font-size: 14px;
  color: #c5d8ff
}

.dc-con {
  height: 60px;
  box-sizing: border-box;
  padding-left: 76px;
  background: url("../../assets/images/electricity/dc-icon.png") no-repeat left 6px center / 60px;
  font-size: 14px;
  color: #c5d8ff
}

.num-ac {
  font-size: 20px;
  color: #40ecc2;
}

.num-dc {
  font-size: 20px;
  color: #51a1ff;
}
</style>
