import { getRequest, postRequest,monitorGateway, urlTitle} from "@/utils/request";

// 驾驶舱 electricity
export const cockpitSecondApi = {
  // 站点负荷当日用能汇总（用能类型）
  queryCountByUsageType: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/stat/countByUsageType`,params);
  },
  // 站点扩展配置信息
  queryStationsSetStateAll: (params) => {
    return getRequest(`${urlTitle}/stations/settings/stat/all`,params);
  },

  //主要负荷设备统计
  byUsageType: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/powerStat/histotal/byUsageType`,params);
  },
  //上月负荷用能费用占比
  byType: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/powerStat/month/total/byType`,params);
  },
  //上月能耗占比
  byPowerTypeAndType: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/powerStat/month/total/byPowerTypeAndType`,params);
  },
  //年度单耗统计
  unitConsumption: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/powerStat/year/unitConsumption/byYears`,params);
  },
  //实际节能率&目标达成率
  stationsAll: (params) => {
    return getRequest(`${urlTitle}/stations/settings/stat/all`,params);
  },
  //产值单耗趋势
  byYears: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/powerStat/year/unitConsumption/monthly/byYears`,params);
  },
  //用电情况
  hourly: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/powerStat/day/hourly/byType`,params);
  },
  //查询场站
  queryStation: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/stations/byCompanyId`,params);
  },
  stationDetail: (params) => {
    return getRequest(`${urlTitle}/loadEquipments/stations/`+ params + '/summary', );
  },
};